@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --gray-50: 210 20% 98%;
    --gray-100: 202 33% 95%;
    --gray-200: 203 31% 90%;
    --gray-300: 202 35% 83%;
    --gray-400: 201 27% 67%;
    --gray-500: 201 26% 55%;
    --gray-600: 201 29% 41%;
    --gray-700: 201 68% 26%;
    --gray-800: 201 100% 15%;
    --gray-900: 201 100% 10%;

    --teal-50: 166 76% 97%;
    --teal-100: 167 85% 89%;
    --teal-200: 168 84% 78%;
    --teal-300: 171 77% 64%;
    --teal-400: 172 66% 50%;
    --teal-500: 173 80% 40%;
    --teal-600: 175 84% 32%;
    --teal-700: 179 66% 31%;

    --accent-100: 46 100% 45%;

    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --background-brand: 166 76% 97%;
    --foreground-brand: 201 100% 15%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 201 100% 15%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 201 100% 15%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    /*--accent: 210 40% 96.1%;*/
    --accent: 167 85% 89%;
    /*--accent-foreground: 222.2 47.4% 11.2%;*/
    --accent-foreground: 201 100% 15%;

    --destructive: 0 84% 60%;
    --destructive-hover: 0 72% 51%;
    --destructive-foreground: 0 0% 100%;

    --ring: 215 20.2% 65.1%;

    --avatar-border: 172 66% 50%;
    --radius: 0.5rem;

    --button-primary: 45 88% 51%;
    --button-primary-foreground: 201 100% 15%;
    --button-primary-hover: 46 100% 55%;

    --menu-primary: 176 100% 97%;

    --card-border: 202 35% 83%;
    --card-footer: 168 84% 78%;
    --card-footer-foreground: 201 100% 15%;

    --tabs-border: 201 68% 26%;
    --tabs-accent: 201 100% 15%;
    --tabs-accent-foreground: 0 0% 100%;
    --tabs-foreground: 201 26% 55%;

    --footer: 168 84% 78%;
    --footer-foreground: 201 100% 15%;

    --listMarker: 171 77% 64%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 201.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: ;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-backgroundBrand text-foregroundBrand;
  }
}


@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
