@use "sass:math";
@import "./styles/variables.scss";
@charset "utf-8";


@import "./utilities.scss";

// Layout & components
@import "~bootstrap/scss/root";
//@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
//@import "~bootstrap/scss/utilities/api";


html,
body,
.__next {
  //height: 100%;
}

.page-wrapper {
  //overflow: auto;
}


body {
  //font-family: $body-family;
  //font-size: $body-font-size;
  //line-height: $body-line-height;
  //color: $brand-400;
  //background: $brand-100;
}

#__next {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  //min-height: 100vh;
  //display: flex;
  //flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    //margin-top: auto;
  }

  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    //flex-shrink: 0;
  }
}

.page-wrapper {
  display: flex;
  min-height: calc(100vh - 100px);
  flex-direction: column;
}

.page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: $s-13;

  &--no-padding {
    padding-bottom: 0;
  }
}

.page-content > *:first-child:last-child {
  height: 100%;
  flex: 1;
}

.link--unstyled,
.link--unstyled:visited,
.link--unstyled:hover {
  text-decoration: none;
  color: inherit;
}

.link-primary {
  color: $brand-300;
  font-weight: $fw-bold;
}

.link-primary:hover,
.link-primary:visited {
  color: $brand-300;
  text-decoration: none;
}

.link-secondary,
.link-secondary:visited {
  color: $brand-400;
  font-weight: $fw-bold;
}
.link-secondary:hover {
  text-decoration: underline;
  color: $brand-400;
}

@include media-breakpoint-down(md) {
  .page-content {
    padding-bottom: $s-8;

    &--no-padding {
      padding-bottom: 0;
    }
  }
}

.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: none;
}

//temp fix for next.js css import order issue
@include media-breakpoint-up(sm) {
  .calendar-list__row {
    flex: 1 0 0%;
  }
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

@each $name, $s in $fontSizes {
  .typography--size-#{$name} {
    font-size: $s;
    line-height: map-get($line-heights, $name);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/* temp fix for wysiwyg styles */
.prose p[style],
.prose li[style] {
  line-height: revert!important;
  margin: revert!important;
}
