@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $key, $value in $fontSizes {
      .font-size#{$infix}-#{$key} {
        content: "#{$infix}";
        font-size: #{$value}!important;
        line-height: map-get($line-heights, $key)!important;
      }
    }

    @each $key, $value in $colors {
      .text#{$infix}-#{$key} {
        color: $value!important;
      }
    }

    @each $key, $value in $font-weights {
      .font-weight#{$infix}-#{$key} {
        font-weight: $value!important;
      }
    }

    @each $key, $value in $colors {
      .bg#{$infix}-#{$key} {
        background-color: $value!important;
      }
    }
  }
}
