@use "sass:math";

$grid-columns: 8;
$grid-gutter-width: 30px;
$spacer: 10px;
$spacers: (
        0: 0,
        1: math.div($spacer, 2),
        2: $spacer,
        3: $spacer * 1.5,
        4: $spacer * 2,
        5: $spacer * 2.5,
        6: $spacer * 3,
        7: $spacer * 4,
        8: $spacer * 5,
        9: $spacer * 6,
        10: $spacer * 7,
        11: $spacer * 8,
        12: $spacer * 9,
        13: $spacer * 10,
        14: $spacer * 12,
        15: $spacer * 15,
        16: $spacer * 20
);

$fontSizes:   (
        1: 46px,
        2: 40px,
        3: 32px,
        4: 30px,
        5: 24px,
        6: 20px,
        7: 18px,
        8: 16px,
        9: 14px,
        10:12px,
        11:10px
);

$line-heights: (
        1: 1.2,
        2: 1.2,
        3: 1.2,
        4: 1.3,
        5: 1.2,
        6: 1.2,
        7: 1.4,
        8: 1.4,
        9: 1.4,
        10: 1.4,
        11: 1.4
);

$brand-100: rgb(239, 255, 254);
$brand-100-secondary: rgba(239, 255, 254, .3);
$brand-200: rgb(0, 205, 169);
$brand-200-highlight: rgb(0, 0, 0);
$brand-300: rgb(27, 131, 129);
$brand-300-secondary: rgba(27, 131, 129, .3);
$brand-400: #00334e;
$brand-400-secondary: #9DACB4;
$brand-border-color-1: rgba(0, 51, 79, 0.25);
$brand-modal-overlay: rgba(0, 51, 79, .50);
$brand-table-stripe-1: #EFFFFE;

$brand-gray-alternative: #8099A6;

$brand-gray-100: #F0F2F3;
$brand-gray-200: rgba(0, 0, 0, 0.30);
$brand-gray-300: #8E9091;
$brand-gray-400: #707070;
$brand-gray-500: #525252;
$brand-gray-600: #212121;

$brand-light: #FFFFFF;
$brand-dark: #121212;

$brand-accent: #E7B000;
$brand-accent-dark: #E78B00;

$brand-red: #9B1555;

$brand-error-bg: rgba(231, 97, 0, 0.1);

$brand-warning: #E7B000;
$brand-error: #E76100;
$brand-success: $brand-200;

$training-plan-accent-1: #F10E93;
$training-plan-accent-2: #0065FC;
$training-plan-accent-3: #3BD02E;
$training-plan-accent-4: #60B527;
$training-plan-accent-5: #F79009;
$training-plan-accent-6: #338213;
$training-plan-accent-7: #E6141E;

$training-plan-button-bg: #F9FAFA;

$progress-default: $brand-300;
$progress-excellent: $training-plan-accent-6;
$progress-good: $training-plan-accent-4;
$progress-moderate: $training-plan-accent-5;
$progress-low: $training-plan-accent-7;


$base-font-size: 10px;

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($base-font-size)}rem;
}

$colors: (
        brand-100: $brand-100,
        brand-200: $brand-200,
        brand-300: $brand-300,
        brand-400: $brand-400,
        brand-gray-100: $brand-gray-100,
        brand-gray-200: $brand-gray-200,
        brand-gray-300: $brand-gray-300,
        brand-gray-400: $brand-gray-400,
        brand-gray-500: $brand-gray-500,
        brand-gray-600: $brand-gray-600,
        brand-light: $brand-light,
        brand-dark: $brand-dark,
        brand-accent: $brand-accent,
        brand-warning: $brand-warning,
        brand-error: $brand-error,
        brand-success: $brand-success,
        training-plan-accent-1: $training-plan-accent-1,
        training-plan-accent-2: $training-plan-accent-2,
        training-plan-accent-3: $training-plan-accent-3,
        training-plan-accent-4: $training-plan-accent-4,
        training-plan-accent-5: $training-plan-accent-5,
        training-plan-accent-6: $training-plan-accent-6,
        training-plan-accent-7: $training-plan-accent-7,
);

$font-weights: (
        regular: 400,
        medium: 500,
        semi-bold: 600,
        bold: 700,
);

$fs-1: map-get($fontSizes, 1);
$fs-2: map-get($fontSizes, 2);
$fs-3: map-get($fontSizes, 3);
$fs-4: map-get($fontSizes, 4);
$fs-5: map-get($fontSizes, 5);
$fs-6: map-get($fontSizes, 6);
$fs-7: map-get($fontSizes, 7);
$fs-8: map-get($fontSizes, 8);
$fs-9: map-get($fontSizes, 9);
$fs-10: map-get($fontSizes, 10);
$fs-11: map-get($fontSizes, 11);

$lh-1: map-get($line-heights, 1);
$lh-2: map-get($line-heights, 2);
$lh-3: map-get($line-heights, 3);
$lh-4: map-get($line-heights, 4);
$lh-5: map-get($line-heights, 5);
$lh-6: map-get($line-heights, 6);
$lh-7: map-get($line-heights, 7);
$lh-8: map-get($line-heights, 8);
$lh-9: map-get($line-heights, 9);
$lh-10: map-get($line-heights, 10);
$lh-11: map-get($line-heights, 11);

$fw-regular: map-get($font-weights, "regular");
$fw-medium: map-get($font-weights, "medium");
$fw-semi-bold: map-get($font-weights, "semi-bold");
$fw-bold: map-get($font-weights, "bold");

$s-1: map-get($spacers, 1);
$s-2: map-get($spacers, 2);
$s-3: map-get($spacers, 3);
$s-4: map-get($spacers, 4);
$s-5: map-get($spacers, 5);
$s-6: map-get($spacers, 6);
$s-7: map-get($spacers, 7);
$s-8: map-get($spacers, 8);
$s-9: map-get($spacers, 9);
$s-10: map-get($spacers, 10);
$s-11: map-get($spacers, 11);
$s-12: map-get($spacers, 12);
$s-13: map-get($spacers, 13);
$s-14: map-get($spacers, 14);
$s-15: map-get($spacers, 15);
$s-16: map-get($spacers, 16);

$z-index-max: 1000;
$z-index-modal: 100;
$z-index-sticky: 90;
$z-index-navbar: 80;

$text: $brand-400;
$text-strong: $brand-400;
$link: $brand-400;
$link-hover-color: $brand-400;

$border-radius-3: 6px;
$border-radius-2: 4px;
$border-radius-1: 2px;

//$body-family: "Montserrat", "DejaVu Sans", "Verdana", sans‑serif;
$body-font-size: $fs-8;
$body-line-height: 1.4;

$box-shadow-1: 1px 1px 20px rgba(0, 51, 78, 0.05);
$box-shadow-2: 1px 1px 20px rgba(0, 51, 78, 0.15);
$box-shadow-2-hover: 1px 4px 40px rgba(0, 51, 78, 0.2);

$box-shadow-2-featured: 1px 1px 20px rgba(0, 51, 78, 0.2);
$box-shadow-2-featured-hover: 1px 4px 40px rgba(0, 51, 78, 0.35);

//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px,
//        xxl: 1920px
//);
//
//
//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1150px,
//        xxl: 1720px
//);
//
//
//@mixin make-max-widths-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
//  @each $breakpoint, $container-max-width in $max-widths {
//    @include media-breakpoint-up($breakpoint, $breakpoints) {
//      max-width: $container-max-width;
//    }
//  }
//}

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
//@import "~bootstrap/scss/utilities";
